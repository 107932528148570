<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <RouterLink
        class="bg-red-400 h-full flex items-center justify-center"
        style="width: 50vw"
        to="/"
        aria-label="Visit homepage"
      >
        <div class="flex flex-col items-center justify-center gap-6">
          <p class="text-white font-extrabold text-3xl">Synergy for Sustainable Growth</p>
          <img src="/backlogin.jpg" class="w-3/5" alt="logo" />
          <!-- <p class="font-extrabold text-white text-3xl">HRIS SABANA</p> -->
        </div>
      </RouterLink>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <!-- <VuesticLogo class="mb-2" start="#0E41C9" /> -->
            </RouterLink>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
// import VuesticLogo from '../components/VuesticLogo.vue'

const breakpoint = useBreakpoint()
</script>
