import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users.vue'),
      },
      {
        name: 'employee',
        path: 'employee',
        component: () => import('../pages/employee.vue'),
      },
      {
        name: 'business_unit',
        path: 'business_unit',
        component: () => import('../pages/businessUnit.vue'),
      },
      {
        name: 'meeting_room',
        path: 'meeting_room',
        component: () => import('../pages/meetingRoom.vue'),
      },
      {
        name: 'vehicle',
        path: 'vehicle',
        component: () => import('../pages/vehicle.vue'),
      },
      {
        name: 'dinas_keluar',
        path: 'dinas_keluar',
        component: () => import('../pages/dinasKeluar.vue'),
      },
      {
        name: 'forgot_attendance',
        path: 'forgot_attendance',
        component: () => import('../pages/forgotAttendace.vue'),
      },
      {
        name: 'booking_meeting',
        path: 'booking_meeting',
        component: () => import('../pages/bookMeetRoom.vue'),
      },
      {
        name: 'appr_book_meeting',
        path: 'appr_book_meeting',
        component: () => import('../pages/apprBookMeetRoom.vue'),
      },
      {
        name: 'booking_kendaraan',
        path: 'booking_kendaraan',
        component: () => import('../pages/bookingVehicle.vue'),
      },
      {
        name: 'appr_mgr_kendaraan',
        path: 'appr_mgr_kendaraan',
        component: () => import('../pages/apprMgrBookVehicle.vue'),
      },
      {
        name: 'appr_hr_kendaraan',
        path: 'appr_hr_kendaraan',
        component: () => import('../pages/apprHrBookVehicle.vue'),
      },
      {
        name: 'appr_security_kendaraan',
        path: 'appr_security_kendaraan',
        component: () => import('../pages/apprSecurityBookVehicle.vue'),
      },
      {
        name: 'appr_mgr_permit',
        path: 'appr_mgr_permit',
        component: () => import('../pages/apprMgrDinasKeluar.vue'),
      },
      {
        name: 'appr_hr_permit',
        path: 'appr_hr_permit',
        component: () => import('../pages/apprHrDinasKeluar.vue'),
      },
      {
        name: 'appr_forgot_attendance_mgr',
        path: 'appr_forgot_attendance_mgr',
        component: () => import('../pages/apprForgotAttendance.vue'),
      },
      {
        name: 'appr_hr_forgot_attendance',
        path: 'appr_hr_forgot_attendance',
        component: () => import('../pages/apprHrForgotAttendance.vue'),
      },
      {
        name: 'loan_item',
        path: 'loan_item',
        component: () => import('../pages/loanItem.vue'),
      },
      {
        name: 'appr_loan_item_mgr',
        path: 'appr_loan_item_mgr',
        component: () => import('../pages/apprMgrLoanItem.vue'),
      },
      {
        name: 'back_pay',
        path: 'back_pay',
        component: () => import('../pages/backpay.vue'),
      },
      {
        name: 'appr_backpay',
        path: 'appr_backpay',
        component: () => import('../pages/apprMgrBackpay.vue'),
      },
      {
        name: 'appr_hr_backpay',
        path: 'appr_hr_backpay',
        component: () => import('../pages/apprHrBackpay.vue'),
      },
      {
        name: '/report/permit',
        path: 'report/permit',
        component: () => import('../pages/reports/reportPermit.vue'),
      },
      {
        name: '/report/bookvehicl',
        path: '/report/bookvehicl',
        component: () => import('../pages/reports/reportBookingVehicle.vue'),
      },
      {
        name: '/report/booking_meet',
        path: 'report/booking_meet',
        component: () => import('../pages/reports/reportBookingMeet.vue'),
      },
      {
        name: '/report/backpay',
        path: '/report/backpay',
        component: () => import('../pages/reports/reportBackpay.vue'),
      },
      {
        name: '/report/loan_item',
        path: '/report/loan_item',
        component: () => import('../pages/reports/reportLoanItem.vue'),
      },
      {
        name: '/dept',
        path: '/dept',
        component: () => import('../pages/dept.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!window.localStorage.getItem('token')) {
        next({
          path: '/auth/login',
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'login_1010036',
        path: 'login_1010036',
        component: () => import('../pages/auth/LoginAdmin.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: 'appr_dinas_keluar',
    path: '/appr_dinas_keluar',
    component: () => import('../layouts/ApprDinasKeluar.vue'),
  },
  {
    name: 'appr_hr_dinas_keluar',
    path: '/appr_hr_dinas_keluar',
    component: () => import('../layouts/ApprHrDinasKeluar.vue'),
  },
  {
    name: 'appr_lupa_absen',
    path: '/appr_lupa_absen',
    component: () => import('../layouts/ApprLupaAbsen.vue'),
  },
  {
    name: 'appr_hr_lupa_absen',
    path: '/appr_hr_lupa_absen',
    component: () => import('../layouts/ApprHrLupaAbsen.vue'),
  },
  {
    name: 'appr_meeting',
    path: '/appr_meeting',
    component: () => import('../layouts/ApprMeeting.vue'),
  },
  {
    name: 'appr_kendaraan',
    path: '/appr_kendaraan',
    component: () => import('../layouts/ApprKendaraan.vue'),
  },
  {
    name: 'hr_kendaraan_appr',
    path: '/hr_kendaraan_appr',
    component: () => import('../layouts/ApprHrKendaraan.vue'),
  },
  {
    name: 'appr_hr1_kendaraan',
    path: '/appr_hr1_kendaraan',
    component: () => import('../layouts/ApprHr1Kendaraan.vue'),
  },
  {
    name: 'backpay_appr',
    path: '/backpay_appr',
    component: () => import('../layouts/ApprBackpay.vue'),
  },
  {
    name: 'backpay_hr_appr',
    path: '/backpay_hr_appr',
    component: () => import('../layouts/ApprHrBackpay.vue'),
  },
  {
    name: 'loan_item_appr',
    path: '/loan_item_appr',
    component: () => import('../layouts/ApprLoanItem.vue'),
  },
  {
    name: 'loan_item_dept_appr',
    path: '/loan_item_dept_appr',
    component: () => import('../layouts/ApprLoanItemDept.vue'),
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
